import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Search from "../../components/Search"

const Jobs = ({ data, location }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Jobs",
  })

  return (
    <Layout>
      <Seo title="Top Tech Jobs in Milwaukee" />
      <Breadcrumbs crumbs={crumbs} />
      <div className="container">
        <Search
          name="job-search"
          data={data.jobs.nodes.sort((a, b) => {
            return new Date(b.modified) - new Date(a.modified)
          })}
          title="Explore tech jobs in Milwaukee"
          type="jobs"
          placeholder="Search by company name, positions, or industry"
        />
      </div>
    </Layout>
  )
}

export default Jobs

export const searchQuery = graphql`
  query {
    jobs: allWpJobPost {
      nodes {
        modified
        jobPostings {
          url
          typeOfPosition
          techStack
          starttime
          roleCategory
          memberstackId
          jobTitle
          jobDescription
          industry
          featured
          expirationDate
          endtime
          companyUrl
          companyState
          companyName
          companyCity
        }
      }
    }
  }
`
