import React, { useState, useMemo } from "react"
import FormInput from "./FormInput"
import Card from "./AllJobPostingCard"
import Pagination from "./Pagination"
import ProfileCallOut from "./ProfileCallOut"
import { CATEGORIES_LIST } from "../constants/categories"
import { INDUSTRIES_LIST } from "../constants/industries"

const Search = ({ title, data, filters, placeholder, name, type }) => {
  const [searchField, setSearchField] = useState("")
  const [active, setActive] = useState(false)
  const [checkIndustry, setIndustry] = useState(false)
  const [checkHiring, setHiring] = useState({})

  const industry = INDUSTRIES_LIST

  const hiring = CATEGORIES_LIST

  const handleChange = (e) => {
    setSearchField(e)
  }

  const showFilters = (e) => {
    setActive((active) => !active)
  }

  const filteredJobs = useMemo(() => {
    const hasIndustryFilter = Object.values(checkIndustry).includes(true)
    const hasHiringFilter = Object.values(checkHiring).includes(true)
    const matchIndustries = (job) => {
      if (hasIndustryFilter) {
        return job.jobPostings.industry?.split(",").some((category) => checkIndustry[category] === true)
      } else return true
    }

    const matchHiring = (job) => {
      if (hasHiringFilter) {
        let roleCategoryFilter = []
        for (let item in JSON.parse(job.jobPostings.roleCategory)) {
          roleCategoryFilter.push(JSON.parse(job.jobPostings.roleCategory)[item].label)
        }

        return roleCategoryFilter.some((category) => checkHiring[category] === true)
      } else return true
    }

    return data
      .filter((job) => {
        return job.jobPostings.companyName?.toLowerCase().includes(searchField.toLowerCase()) || job.jobPostings.typeOfPosition.toLowerCase().includes(searchField.toLowerCase()) || job.jobPostings.jobTitle.toLowerCase().includes(searchField.toLowerCase()) || job.jobPostings.industry?.toLowerCase().includes(searchField.toLowerCase())
      })
      .filter(matchIndustries)
      .filter(matchHiring)
  }, [data, checkIndustry, checkHiring, searchField])

  const filterIndustry = (index, text) => (event) => {
    setIndustry((state) => ({
      ...state,
      [index]: !state[index],
      [text]: event.target.checked,
    }))
  }

  const filterHiring = (index, text) => (event) => {
    setHiring((state) => ({
      ...state,
      [index]: !state[index],
      [text]: event.target.checked,
    }))
  }

  const industryFilters = (
    <div className="search-filters--types industry">
      <h4>Industry</h4>
      <div className="tags--container">
        {industry.map((item, index) => {
          return (
            <label htmlFor={`industry-${index}`} key={`industry-${index}`} className={`${item.categoryClass} ${checkIndustry[index] ? "checked" : ""}`} onChange={filterIndustry(index, item.label)}>
              <input type="checkbox" name={item.label} id={`industry-${index}`} />
              {item.label}
            </label>
          )
        })}
      </div>
    </div>
  )

  const clearFilters = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  const applyFilterButton = (
    <div className="search-filters--controls">
      <button className="button button--primary" onClick={showFilters}>
        Apply Filters
      </button>
      <a href="#" className="link" onClick={clearFilters}>
        Clear Filters
      </a>
    </div>
  )

  if (type === "jobs") {
    filters = (
      <div className="search-filters--container">
        {industryFilters}
        <div className="search-filters--types hiring">
          <h4>Hiring</h4>
          <div className="tags--container">
            {hiring.map((item, index) => {
              return (
                <label htmlFor={`hiring-${index}`} key={`hiring-${index}`} className={`${item.categoryClass} ${checkHiring[index] ? "checked" : ""}`} onChange={filterHiring(index, item.label)}>
                  <input type="checkbox" name={item.label} id={`hiring-${index}`} />
                  {item.label}
                </label>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(50)

  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = filteredJobs.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <>
      <div className="search-resource">
        <h1>{title}</h1>
        <div className="formItems">
          <FormInput name={name} type="search" placeholder={placeholder} onChange={handleChange} />
          <div className="search--controls">
            <i className="fas fa-search"></i>
            <span className="advanced-search" onClick={showFilters}>
              <em role="button" aria-label="Advanced Search" tabIndex="0">
                Advanced Search
              </em>{" "}
              <i className="fas fa-filter"></i>
            </span>
          </div>
        </div>
      </div>
      <div className={`search-filters ${active ? "active" : ""}`}>
        {filters}
        {applyFilterButton}
      </div>
      <h3>Job Listings</h3>
      <div className="pagination-container">
        <div className="paginate">
          <Pagination postsPerPage={postsPerPage} totalPosts={filteredJobs.length} paginate={paginate} />
        </div>
        <div className="pageinate-page">
          <p>Displaying {postsPerPage} of</p>
          <div className="form-field dropdown ">
            <div className="select-container">
              <div className="select-container__select">
                <select onChange={(e) => setPostsPerPage(e.currentTarget.value)}>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          </div>
          <p>results</p>
        </div>
      </div>
      {filteredJobs.map((job, index) => (
        <>
          <Card key={index} posting={job} />
          {index % 5 == 0 ? <EmailCallOut /> : null}
          {index === 10 ? <ProfileCallOut /> : null}
        </>
      ))}
    </>
  )
}
const EmailCallOut = () => {
  return (
    <div className="cta cta--background-default cta--subscription">
      <div className="cta__header">
        <h2>Get top tech opportunities in your inbox</h2>
        <label htmlFor="subscribe">Stay In the Loop!</label>
        <div className="ctct-inline-form" data-form-id="ac8c28f2-0b36-4119-b5b8-7cf578ec606a"></div>
      </div>
    </div>
  )
}

export default Search
